<template>
  <AddBrand
    @saveDetail="saveBrand"
    @cancelCreate="cancelCreate"
    :details="details"
    :update="false"
  />
</template>

<script>
import AddBrand from "../../../components/WebStore/brands/add";
import { mapActions } from "vuex";

export default {
  name: "Add-Brand",
  components: {
    AddBrand,
  },
  data: () => ({
    details: {
      brandName: "",
      slug: "",
      description: "",
      desktopImage: '',
      mobileImage: '',
      JSONData: [],
    },
  }),
  methods: {
    ...mapActions("brand", ["createBrand"]),
    ...mapActions("ecommerce",["algoliaSync","saveBrandJsonSettings"]),

    saveBrand(data) {
      let self = this;
      this.createBrand(data).then((res) => {
        self.algoliaSync()
        self.saveBrandJsonSetting(res.data.data._id,data.JSONData)
        self.$vs.notify({
          title: "Brand Add",
          text: "Brand added successfully",
          color: "success",
        });
        self.$vs.loading.close();
        this.$router.push({ name: "BrandManagement" });
      }).catch((err) => {
        console.error("ERR: ", err);
        self.$vs.notify({
          title: "Brand Add",
          text: err.data.message || "Failed",
          color: "danger",
        })
      });
    },
    cancelCreate() {
      this.$router.push({ name: "BrandManagement" });
    },
    saveBrandJsonSetting(brandId,JSONData) {
      const value = JSONData;   
      this.saveBrandJsonSettings({ value, brandId })
        .then((val) => {
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Failed to save settings.",
            text: "Please try again later.",
            color: "danger",
          });
          console.error(err);
        });
    }
  },
};
</script>
